import * as React from 'react'
import { Link } from 'gatsby'
//import { StickyContainer, Sticky } from 'react-sticky';
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'

import styled from "@emotion/styled";

import HeaderDesktop from "../components/HeaderDesktop";

import {UserSettings} from "../components/user/Settings";




const logo = require('../../assets/images/V7_000_Logo.png')

const CenterV = styled.img`
margin-top:10px;
margin-bottom:6px;
width:4%;
height:auto;
`;

  const StyledHeader = styled.header`
align-items: center;
text-align: center;
z-index:1;

background:white;
opacity:1;
  @media screen and (max-width: 1200px) {

display:none;
   }

`

const Div = styled.div`
height:3em;
    @media screen and (max-width: 1200px) {

display:none;
   }
`





const User = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>

    <HeaderDesktop></HeaderDesktop>

<UserSettings/>
  </IndexLayout>
)

export default User
