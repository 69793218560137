import 'react-dates/lib/css/_datepicker.css';
import Sticky from 'react-stickynode';
import {Link} from "gatsby";
import FloatingLabelInput from 'react-floating-label-input';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import { FormattedMessage } from 'react-intl';

import 'react-slidedown/lib/slidedown.css'


//import Avatar, { ConfigProvider } from 'react-avatar';

const sleep = m => new Promise(r => setTimeout(r, m));


var moment = require('moment');
import * as React from 'react'
import styled from '@emotion/styled'
import {fonts} from "../../styles/variables";
import {retrieveCharges, retrieveCustomer, retrievePaymentIntents, updateCustomer} from "../stripe/functions";
import {getAccessToken, getGuest, getInvoiceInfo, putGuest} from "../cloudbeds/functions";
import {BookingTemplate} from "./BookingTemplate";
/*import {
  getAvatar,
  getCity,
  getCompanyName,
  getCountry,
  getFullName,
  getPostalCode, getProfile,
  getStreet,
  getStreetNumber
} from "../auth/authFunctions";
*/

require('../../styles/global.css');
//const fs = require('fs');

// standard rates for studio, studio plus and suite



const Outer = styled.div`
display:flex;
justify-content: space-between;
width:80%;
margin-left:auto;
margin-right:auto;`;

const Inner = styled.div`

span{
 font-family: "Theano Didot",Regular !important;

}
  border:1px solid rgba(68,68,68,.04);  
background:rgba(68,68,68,.02);
border-radius: 2px;
text-align:center;
padding-top: 40px;
height:360px;
width:360px;
box-shadow: 1px 2px 4px rgba(0,0,0,0.05);


@media screen and (max-width:1200px){
width:100%;
}
`

const NavBar = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;


`

const StyledSticky = styled(Sticky)`
width:40%;

@media screen and (max-width:1200px){
display:none;
}

`

const StickyNav = styled(Sticky)`
width:100%;

@media screen and (max-width:1200px){
display:none;
}

`




const Div = styled.div`
height:auto;
width:60%;
color:rgb(68,68,68,1);

@media screen and (max-width:1200px){
width:100% ;
}
`

const Bookings = styled.div`

`

const Settings = styled.div`

`

const ProfileDiv = styled.div`

`

const A = styled.a`
  height:50px;
  border-top:1px solid rgba(68,68,68,.07);  
  width:100%;
  display:flex;
font-size: 16px;
  align-items:center;
justify-content:center;
  letter-spacing: 2px;
  transition:all ease .3s;
cursor:pointer;


  :hover{
background:rgba(68,68,68,.04);


}
`

const AA = styled.a`
  height:50px;
  border-top:1px solid rgba(68,68,68,.07);  
  border-bottom:1px solid rgba(68,68,68,.07);  
  width:100%;
  display:flex;
  align-items:center;
justify-content:center;
  letter-spacing: 2px;
  transition:all ease .3s;
cursor:pointer;


  :hover{
background:rgba(68,68,68,.04);




}
`

const StyledP = styled.p`
 font-family: "Theano Didot",Regular;
font-size: 200%;
font-style:italic;
line-height: 40px;
margin-top:30px;
color:rgb(68, 68, 68);
text-align:center !important;
  @media screen and (max-width: 1200px) {

   }


`;

const NoBookingsFound = styled.p`
 font-family: "Theano Didot",Regular;
font-size: 100%;
font-style:italic;
line-height: 40px;
margin-top:30px;
color:rgba(68, 68, 68,.5);
text-align:center !important;
  @media screen and (max-width: 1200px) {

   }


`;

const StyledB = styled.p`
 font-family: "Theano Didot",Regular;
font-size: 180%;
font-style:italic;
line-height: 40px;
color:rgb(68, 68, 68);
text-align:left !important;
  @media screen and (max-width: 1200px) {

   }


`;

const StyledLink = styled(Link)`


`


const ArrowImg = styled.img`
margin-left:5px;
margin-bottom:1px;
width:14px;
height:14px;
transition:all ease 1s;


`

const UserNav = styled.div`
margin-top:50px;
`


const ResetPassword = styled.div`
margin-top:4em;
width:100%;
height:auto;
`

const EditAvatar = styled.div`
margin-top:4em;
width:100%;
height:auto;

canvas{
}
`

const BillingAdress = styled.div`
margin-top:2em;
width:100%;
height:auto;
`

const Span = styled.span`
font-weight:bold;
width:100%;
padding-left:5px;
color:rgb(68,68,68,1);

`

const Flex = styled.div`
width:100%;
display:flex;
color:rgb(68,68,68,1);
margin-top:0.7em;
@media screen and (max-width:640px){
display:block;
}

`

const FlexAdress = styled.div`
width:100%;
display:flex;
@media screen and (max-width:640px){
width:100%;
display:block;

}


`
const FlexDiv = styled.div`
width:50%;
font-size:24px !important;
margin-top:1em;

@media screen and (max-width:640px){
width:100%;

}

`

const InputDiv = styled.div`
margin-top:1em;
font-size:24px !important;




`

const RightFlexDiv = styled.div`
width:40%;
margin-left:10%;
font-size:24px !important;
margin-top:1em;


@media screen and (max-width:640px){
width:100%;
margin-left:0px;
margin-top:1em;

}

`

const Input = styled(FloatingLabelInput)`
width:100%;
border-radius: 2px;
font-family: "Theano Didot",Regular;
font-size: 19px;
height:70px;
padding-left:7px;
margin-top:10px;
transition: all .2s ease;
padding-left:7px;
color:rgb(68,68,68,1);


label{
left:7px;
}


:focus{
border-bottom:2px solid #54aaf1;
background: rgba(84, 170, 241,.1);


}


`

const AvatarDiv = styled.div`
display:flex;
margin-top:1.6em;
align-items:center;
justify-content:center;

label{
 font-family: "Theano Didot",Regular !important;
}

`
const FutureSlogan = styled.div`
width:50%;
margin-top:20px;
color:rgb(68,68,68);
margin-left:auto;
margin-right:auto;
transition: .7s all ease;
text-align:center;
  @media screen and (max-width: 666px) {
width:90%;
   }


`

const UpdateButton_2 = styled.div`
cursor:pointer;
height:50px;
min-width:269px;
 display: flex;
 align-items:center;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:19px;
width:50%;
margin-left:auto;
margin-right:auto;
margin-top:9%;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;

  
:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;

}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;

}

:hover {
  color: #fff;
  

  
}
 
:hover:before {

  width: 100%;
}


  @media screen and (max-width: 666px) {
width:80%;
   }
`

const UpdateButton = styled.div`
cursor:pointer;
height:50px;
 display: flex;
 align-items:center;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:19px;
width:50%;
margin-left:auto;
margin-right:auto;
margin-top:9%;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;

  
:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;

}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;

}

:hover {
  color: #fff;
  

  
}
 
:hover:before {

  width: 100%;
}


  @media screen and (max-width: 666px) {
width:80%;
   }
`

const Img = styled.img`
margin-left:auto;
margin-right:auto;
margin-top:2em;
max-width:140px;
max-height:140px;
border-radius:100px;
border:1px solid rgba(68,68,68,.2);


`

const EditFlex = styled.div`
display:flex;
justify-content:center;


`

const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-top:15px;
margin-bottom:15px;
transform: rotate(180deg);
display:flex;
justify-content:center;
align-items:center;

`



const ConditionalBookings   = (props) => {

  return props.reservations.map(function(booking) {
    return <BookingTemplate reservation={booking}/>

  });
}

export class UserSettings extends React.Component {

  constructor(props) {
    super(props)
   this.divRef = React.createRef<ReactFlagsSelect>();
    this.state = {

      guestID:'',
      email:'',
      customerID:'',
      preview: null,
      src: '',
      arrowRotation: 270,
      nameInput:'-',
      firstNameInput:'-',
      lastNameInput:'-',
      companyInput:'-',
      addressInput:'-',
      streetInput:'-',
      streetNumberInput:'-',
      postalCodeInput:'-',
      cityInput:'-',
      countryInput:'-',
      country:'DE',
      phoneInput:'-',
      showSpinner:true,
      showBookingSpinner:false,
      guestData:{},
      showBookings:false,
      reservations:[],
      future:false




    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.showBookings = this.showBookings.bind(this)
    this.hideBookings = this.hideBookings.bind(this)
    this.getBookings = this.getBookings.bind(this)
    this.onFuture=this.onFuture.bind(this)
  }


  onFuture(){
    this.setState({
      future:!this.state.future
    })

  }

showBookings(){
  this.setState({showBookings: true})

}

hideBookings(){
  this.setState({showBookings: false})

}
  async handleSubmit(){
    if(this.state.firstNameInput != '' &&  this.state.lastNameInput != ''
      && this.state.email!='' && this.state.countryInput!='') {

      await this.setState({showUpdateSpinner: true})

      let guestData = {
        guestID: this.state.guestID,
        firstName: this.state.firstNameInput,
        lastName: this.state.lastNameInput,
        email: this.state.email,
        companyName: this.state.companyInput,
        address: this.state.addressInput,
        zip: this.state.postalCodeInput,
        city: this.state.cityInput,
        country: this.state.countryInput,
        phone: this.state.phoneInput
      }
      const putGuestResponse = await putGuest(await guestData)
      const parsed = JSON.parse(putGuestResponse.body)
      if (parsed.success) {
        //todo: show success notification
        await this.setState({showUpdateSpinner: false})

      }
      else {
        //todo: show error notification
        await this.setState({showUpdateSpinner: false})

      }
    }
    else{
      //todo: show please fill out required input fields error notification

      //console.log('Please fill out the required input fields!')
    }
  }


  onClose() {
    this.setState({preview: null})
  }

  onCrop(preview) {
    this.setState({preview})
  }

  onBeforeFileLoad(elem) {
    if(elem.target.files[0].size > 171680){
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  handleNameChange(event) {
    this.setState({nameInput: event.target.value});
  }
  handleFirstNameChange(event) {
    this.setState({firstNameInput: event.target.value});
  }
  handleLastNameChange(event) {
    this.setState({lastNameInput: event.target.value});
  }

  handleCompanyChange(event) {
    this.setState({companyInput: event.target.value});
  }

  handleAddressChange(event) {
    this.setState({addressInput: event.target.value});
  }

  handlePostalCodeChange(event) {
    this.setState({postalCodeInput: event.target.value});
  }
  handleCityChange(event) {
    this.setState({cityInput: event.target.value});
  }

  handleCountryChange(countryCode) {
    this.setState({countryInput: countryCode});
  }

  handlePhoneChange(event) {
    this.setState({phoneInput: event.target.value});
  }

  rotate(){
    let newRotation = this.state.arrowRotation;
    if(newRotation === 270){
      newRotation += 90;
    }
    else
    if(newRotation === 360){
      newRotation -= 90;
    }
    this.setState({
      arrowRotation: newRotation,
    })
  }


async componentDidMount(){
  await this.setState({

    stripe_customer_id:window.localStorage.stripe_customer_id

  });

  const c = await retrieveCustomer(window.localStorage.stripe_customer_id);
    const customer = await c.customer
  const token = await getAccessToken()
  const parsedTok = await JSON.parse(token.body)
    const g = await getGuest(customer.metadata.guestID, parsedTok)
    const guest = await JSON.parse(g.body)
  //console.log(guest)
 // this.refs.userFlag.updateSelected("UK")

  if(guest.success) {
    await this.setState({

      guestID:customer.metadata.guestID,
      firstNameInput: guest.data.firstName,
      lastNameInput: guest.data.lastName,
      companyInput: guest.data.address2,
      addressInput: guest.data.address,
      email:guest.data.email,
      postalCodeInput: guest.data.zip,
      cityInput: guest.data.city,
      countryInput:guest.data.country,
      phoneInput:guest.data.phone,
      showSpinner:false,
      guestData:guest.data

    });
  }


  const setState = () => {

    this.setState({



    });
  }

  window.addEventListener('unload', function(event) {

setState()
  });

  window.addEventListener('onload', function(event) {
   setState()

  });

}


//todo: update cloudbeds guest and update corresponding stripe customer
updateGuestData(){
let guest_data= {
    guestID: this.state.guestID,
    guestFirstName:this.state.firstNameInput,
    guestLastName:this.state.lastNameInput,
    guestPhone:this.state.phoneNumberInput,
    guestAddress:this.state.addressInput,
    guestZip:this.state.postalCodeInput,
    guestCity:this.state.cityInput,
    guestCountry:this.state.countryInput

}

let updater = putGuest(guest_data);
  let now = moment().format('MMMM Do YYYY, h:mm:ss a');

  updater.then(function(update) {
    if(update.success===true){
      //todo: show billing details updated successful notification update stripe customer
      let customerData= {
        address:{
                  line1:this.state.addressInput,
                  city: this.state.cityInput,
                  country: this.state.country,
                  postal_code: this.state.postalCodeInput },
        name: this.state.firstNameInput + ' ' + this.state.lastNameInput,
        phone:this.state.phoneNumberInput,
        metadata:{
          guestID: this.state.guestID,
          lastChange: now
        }
      }
      let stripeUpdater = updateCustomer(this.state.customerID,customerData);
      stripeUpdater.then(function(res) {
        if(res.object==='customer'){
          //todo: send successful update customer in stripe mail to devops
        }
        else{
          //todo: send failed to update customer in stripe mail devops
        }

      });


      }
    else{
      //todo: show failed to update billing details notification

    }
  });


}


async getBookings(){
  await this.setState({
    showBookingSpinner:true
  })

    //console.log(this.state.stripe_customer_id)
  const charges = await retrieveCharges(this.state.stripe_customer_id)
  const intents = await retrievePaymentIntents(this.state.stripe_customer_id)

  let reservationIDs = []

  if(charges.data!=undefined || charges.data!=null) {
    for (let c = 0; c < charges.data.length; c++) {
      let utcSeconds = charges.data[c].created;
      let d = await new Date(0); // The 0 there is the key, which sets the date to the epoch
      let created = await d.setUTCSeconds(utcSeconds)
      await reservationIDs.push({
        reservationID: charges.data[c].metadata.reservationID,
        timestamp: moment(created)._d

      })
    }
  }

  if(intents.data!=undefined || intents.data!=null) {
    for (let c = 0; c < intents.data.length; c++) {
      let utcSeconds = intents.data[c].created;
      let d = await new Date(0); // The 0 there is the key, which sets the date to the epoch
      let created = await d.setUTCSeconds(utcSeconds)
      await reservationIDs.push({
        reservationID: intents.data[c].metadata.reservationID,
        timestamp: moment(created)._d

      })
    }
  }

  await reservationIDs.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return b.date - a.date;
  });

  await this.setState({
    reservationIDs: reservationIDs,
  })

  //only fetch last three reservations
  let reservations = []

  if(reservationIDs.length>3){
    await reservationIDs.slice(0,3)
  }
  //console.log(await reservationIDs)


  for(const item of reservationIDs){
    await sleep(1000)
//console.log(item)
    if(item.reservationID!=undefined) {
      const reservation = await getInvoiceInfo(item.reservationID)
      //console.log(await reservation.data)
      await reservation.data.customFields.push(item.reservationID)
      await reservations.push(reservation.data)
    }
  }
  await     this.setState({
    reservations: reservations,
  })

  if(charges.object==='list'){
    this.setState({
      charges: charges,
    })
  }
  else{
    //todo: handle error
    //console.log('User have no charges or charges could not be loaded')
  }

  if(intents.object==='list'){
    this.setState({
      intents: intents,
    })
  }
  else{
    //todo: handle error
    //console.log('User have no intents or intents could not be loaded')
  }

  this.setState({
    showBookingSpinner:false
  })
}

//todo: update auth0 avatar
updateAvatar(){


}

//todo: send auth0 reset password link
sendResetPassowrd(){


}



  render() {
    // console.log("Suite_Blocker: " + suite_blocker["0"][1]);

    return (
      <ProfileDiv>
        <Outer ref='stick-end' id={'sticky-end'}>

          <StyledSticky innerZ={99} enabled={true} top={150} bottomBoundary='#sticky-end'>
            <Inner>
              <UserNav>
                <A onClick={this.showBookings}><FormattedMessage id={'user_nav_1'}/></A>

                <AA onClick={this.hideBookings} ><FormattedMessage id={'user_nav_2'}/></AA>




              </UserNav>
            </Inner>
          </StyledSticky>
          <Div>
            <Settings style={{display:(this.state.showBookings) ? 'none':'block'}}>

              <BillingAdress>
                <StickyNav  innerZ={99} enabled={true} top={150} bottomBoundary='#sticky-end'>  </StickyNav>
                <StyledP> <FormattedMessage id={'user_header_2'}/></StyledP>
                <StyledSpinner style={{display:(this.state.showSpinner) ? 'flex':'none'}}>
                  <Loader
                    type="Triangle"
                    color="rgb(68,68,68)"
                    height={55}
                    width={55}
                    // timeout={30000} //3 secs

                  />
                </StyledSpinner >
                <form style={{display:(this.state.showSpinner) ? 'none':'block'}}>
                  <Flex>
                    <FlexDiv>
                      <Input label={<FormattedMessage  id={'payment_text_1'}/>} onChange={this.handleFirstNameChange}  value={this.state.firstNameInput} name="name" type="text" required />
                    </FlexDiv>
                    <RightFlexDiv>
                      <Input label={<FormattedMessage  id={'payment_text_2'}/>} onChange={this.handleLastNameChange}  value={this.state.lastNameInput} name="name" type="text" required />
                    </RightFlexDiv>
                  </Flex>

                  <InputDiv>

                <Input label={<FormattedMessage  id={'billing_text_1'}/>} onChange={this.handleCompanyChange}placeholder='(optional)' value={this.state.companyInput} name="name" type="text" />
                </InputDiv>

                  <InputDiv>

                    <Input label={<FormattedMessage  id={'billing_text_2'}/>} onChange={this.handleAddressChange}placeholder='(optional)' value={this.state.addressInput} name="name" type="text" />
                  </InputDiv>
<FlexAdress>
                  <FlexDiv>
                <Input label={<FormattedMessage  id={'payment_text_3'}/>} onChange={this.handlePostalCodeChange} value={this.state.postalCodeInput} name="name" type="text" required />
                </FlexDiv>

                  <RightFlexDiv>
                <Input label={<FormattedMessage  id={'billing_text_3'}/>} onChange={this.handleCityChange} value={this.state.cityInput} name="name" type="text" />
                </RightFlexDiv>
</FlexAdress>
                  <InputDiv>
                    <ReactFlagsSelect
                      ref={this.divRef}
                      defaultCountry="DE"
                      searchable={true}
                      onSelect={this.handleCountryChange}
                    />
                </InputDiv>

                  <UpdateButton onClick={this.handleSubmit}>  <StyledSpinner style={{display:(this.state.showUpdateSpinner) ? 'flex':'none'}}>
                    <Loader
                      type="Triangle"
                      color="white"
                      height={36}
                      width={36}
                      // timeout={30000} //3 secs

                    />
                  </StyledSpinner > <div style={{display:(this.state.showUpdateSpinner) ? 'none':'block'}}><FormattedMessage  id={'btn_update'}/></div></UpdateButton>
                </form>



              </BillingAdress>

            </Settings>
            <div style={{display:(this.state.showBookings) ? 'block':'none'}} >
              <Bookings>
                <StyledP> <FormattedMessage id={'user_header_1'}/></StyledP>
                <StyledSpinner style={{display:(this.state.showBookingSpinner) ? 'flex':'none'}}>
                  <Loader
                    type="Triangle"
                    color="rgb(68,68,68)"
                    height={36}
                    width={36}
                    // timeout={30000} //3 secs

                  />
                </StyledSpinner >
                <UpdateButton_2 onClick={this.onFuture} ><FormattedMessage id={'btn_show_bookings'}/></UpdateButton_2>
                <FutureSlogan style={{display:(this.state.future ? 'block':'none')}}>
                  <FormattedMessage id={'future'}/>
                </FutureSlogan>
                <ConditionalBookings reservations={this.state.reservations}/>
                <NoBookingsFound style={{display:(this.state.showBookingSpinner && this.state.reservations.length!=0 ) ? 'block':'none'}}>No bookings found</NoBookingsFound>
              </Bookings>
            </div>
          </Div>
        </Outer>
      </ProfileDiv>

    )
  }
}
