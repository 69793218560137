  import React, {ElementConfig} from 'react'
//import { Button } from 'reakit'
import styled from "@emotion/styled";
import { Link, navigate } from 'gatsby'

import Select, {components} from 'react-select'
import Textarea from 'react-textarea-autosize';
import {AiOutlineFilePdf, AiOutlinePhone} from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineShareAlt } from "react-icons/ai";

  import { IoIosArrowUp } from "react-icons/io";

import {Document, Page, Text, View, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';


const pdfStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});




const arrowDown = require('../../../assets/images/down-arrow.png')

//import { handleLogin, logout, isLoggedIn } from "../../server/auth"
import Auth0Lock from 'auth0-lock';

//import TimePicker from 'react-times';

// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'


import Popup from 'reactjs-popup'
import TimePickerWrapper from "../timepicker/TimePickerWrapper";
import {BookBreakfast} from "./BookBreakfast";
import {AddBreakfast} from "../booking/specials/AddBreakfast";
import {AddChampagne} from "../booking/specials/AddChampagne";
import {AddBikes} from "../booking/specials/AddBikes";
import {AddLaundry} from "../booking/specials/AddLaundry";
import {fonts} from "../../styles/variables";
import {refundCharge, refundPaymentIntent} from "../stripe/functions";
import {cancelReservation, postArrivalTime, postReservationItem, postReservationNotes} from "../cloudbeds/functions";
import moment from "moment";

const ArrowImg2 = styled.img`
fill:black !important;
width:16px;
height:16px;
margin: 0 11px 0 11px;

`

let specialsGuestOptions = [
  {value: 0, label: '0'},
  {value: 1, label: '1'},
  {value: 2, label: '2'},
  {value: 3, label: '3'},
  {value: 4, label: '4'},
  {value: 5, label: '5'},
  {value: 6, label: '6'},
  {value: 7, label: '7'},
  {value: 8, label: '8'},
  {value: 9, label: '9'},
  {value: 10, label: '10'},
  {value: 11, label: '11'},
  {value: 12, label: '12'},


]

let specialsDaysOptions = [
  {value: 0, label: '0'},
  {value: 1, label: '1'},
  {value: 2, label: '2'},
  {value: 3, label: '3'},
  {value: 4, label: '4'},
  {value: 5, label: '5'},
  {value: 6, label: '6'},
  {value: 7, label: '7'},
  {value: 8, label: '8'},
  {value: 9, label: '9'},
  {value: 10, label: '10'},
  {value: 11, label: '11'},
  {value: 12, label: '12'},
  {value: 13, label: '13'},
  {value: 14, label: '14'},
  {value: 15, label: '15'},
  {value: 16, label: '16'},
  {value: 17, label: '17'},
  {value: 18, label: '18'},
  {value: 19, label: '19'},
  {value: 20, label: '20'},
  {value: 21, label: '21'},
  {value: 22, label: '22'},
  {value: 23, label: '23'},
  {value: 24, label: '24'},
  {value: 25, label: '25'},
  {value: 26, label: '26'},
  {value: 27, label: '27'},
  {value: 28, label: '28'},
  {value: 29, label: '29'},
  {value: 30, label: '30'},
  {value: 31, label: '31'}


]

let guestOptions = [
  {value: 1, label: '1 guest'},
  {value: 2, label: '2 guests'},
  {value: 3, label: '3 guests'},
  {value: 4, label: '4 guests'},
  {value: 5, label: '5 guests'},
  {value: 6, label: '6 guests'},
  {value: 7, label: '7 guests'},
  {value: 8, label: '8 guests'},
  {value: 9, label: '9 guests'},
  {value: 10, label: '10 guests'},
  {value: 11, label: '11 guests'},
  {value: 12, label: '12 guests'}
]

let bedOptions_1 = [
  {value: 1, label: '1 double bed'},
  {value: 2, label: '2 single beds'},

]

let bedOptions_2 = [
  {value: 3, label: '1 double bed, 1 single bed'},
  {value: 4, label: '3 single beds'},

]


const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>

) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowImg2 src={arrowDown}/>
    </components.DropdownIndicator>
  );
};

const customGuestStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null
  }),
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      display: 'flex',

      borderRadius: '2px',
      fontSize: '19px',
      borderTop: '1px solid lightgrey',
      borderLeft: '1px solid lightgrey',
      borderRight: '1px solid lightgrey',
      borderBottom: isFocused ? '2px solid #54aaf1' :'1px solid lightgrey',
      boxShadow: isFocused ? null : null,

      "&:hover": {
        // Overwrittes the different states of border
        borderColor: isFocused ? "none" : "rgba(68,68,68,.5)",


      }
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      background: isSelected ? 'rgb(24,140,236)' : 'white',
      color: isSelected ? 'white' : 'rgb(68,68,68)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      "&:hover": {
        // Overwrittes the different states of border
        background: isSelected ? "rgba(24,140,236,1)" : "rgba(24,140,236,.2)",


      }

    };
  },



  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}


const StyledSelect = styled.div`
width:100%;
 font-family: "Theano Didot",Regular;
font-size:19px;
text-align:center;





`


const NextButton = styled.div`
cursor:pointer;
height:50px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:19px;
width:50%;
margin-left:auto;
margin-right:auto;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;

  
:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}
 
:hover:before {
  width: 100%;
}

  @media screen and (max-width: 666px) {
width:80%;
   }
`

const CancelButton = styled.div`
cursor:pointer;
height:50px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:19px;
width:50%;
margin-left:auto;
margin-right:auto;
margin-top:10%;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;

  
:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #b30000;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}
 
:hover:before {
  width: 100%;
}

  @media screen and (max-width: 666px) {
width:80%;
   }
`

const StyledB = styled.p`
 font-family: "Theano Didot",Regular;
font-size: 160%;
font-style:italic;
line-height: 40px;
color:rgb(68, 68, 68);
text-align:left !important;
margin-top:1em;
  @media screen and (max-width: 1200px) {

   }


`;

const ButtonDiv = styled.div`
width:100%;
margin-top:7%;
display:flex;
align-items: center;
justify-content:center;
`

const A = styled.div`
display:flex;
justify-content:center;
align-items:center;
color:white;
`
let options = {
  container: 'pop-lockButton-container'
};


const Div = styled.div`
width:100%;
height:auto;
padding-left:20px;
padding-right:30px;
padding-top:20px;
padding-bottom:20px;
border-radius:2px;
  border:1px solid rgba(68,68,68,.04);  
background:rgba(68,68,68,.02);

  
.DateRangePickerInput_arrow_svg{

}

.css-hzma7o-BikesDiv{
margin-right:0px;
}

.css-8d6285-BikesDiv{
margin-right:0px;

}

.css-1e3emji-RadioDiv{
display:none !important;
}

.css-yt2q09-StyledP{
font-size: 160%;
margin-left: 28px;
}

@media screen and (max-width:1200px){
padding-left:0px;
padding-right:0px;
}

`

const Span = styled.div`
font-weight:bold;
font-family: "Theano Didot",Regular;
padding-left:7px;
color:rgb(68,68,68,1);

`

const SpanCentered = styled.div`
font-weight:bold;
font-family: "Theano Didot",Regular;
text-align:center;
color:rgb(68,68,68,1);
margin-bottom:2%;

`

const DetailsA = styled.div`
//font-weight:bold;
font-family: "Theano Didot",Regular;
padding-left:7px;
color:rgb(68,68,68,1);
opacity:0.9;

`

const Checkin = styled.span`
padding-left:6px;
margin-right:90px;
font-weight:bold;
color:rgb(68,68,68,1);
`


const DateDiv = styled.div`
width:100%;
padding-left:6px;
padding-right:6px;
display:flex;
justify-content:space-between;

svg{
@media screen and (max-width:345px){
display:none;}
}

  


`

const DivSpecial = styled.div`
width:100%;
padding-left:6px;
padding-right:6px;
//display:flex;
//justify-content:space-between;


`
const DivBreakfast = styled.div`
width:100%;
//padding-left:6px;
//padding-right:6px;
display:flex;
justify-content:space-between;


`

const DivLaundry = styled.div`
width:100%;
//padding-left:6px;
//padding-right:6px;
display:flex;
margin-top:2%;
justify-content:space-between;


`

const CheckinDiv= styled.div`
width:120px;
margin-left:2em;
margin-right:2em;


@media screen and (max-width:1200px){
margin:0px}

`

const SpecialsDiv= styled.div`
width:240px;
margin-left:2em;
margin-right:2em;

@media screen and (max-width:1200px){
margin:0px}

`

const FlexDiv= styled.div`
width:100%;
display:flex;
@media screen and (max-width:400px){
display:block;
  }

`
const FlexDivHeader= styled.div`
width:100%;
display:flex;


`

const FlexDivPadding= styled.div`
width:100%;
display:flex;
padding-top:7%;

@media screen and (max-width:345px){
display:block;
  }


`

const TimePickerDiv = styled.div`
width:60%;
margin-top:10px;

@media screen and (max-width:1200px){
width:auto;
}

.preview_container{
left:15%;

}

`

const FlexBox = styled.div`
display:flex;
`

const DivIDOne = styled.div`
width:100%;
margin-left:7%;
margin-right:2%;
@media screen and (max-width:1200px){
//display:none;
}
`
const DivID = styled.div`
width:70px;
margin-left:2em;
margin-right:2em;
`

const DivArrival = styled.div`
width:auto;
margin-left:2em;
margin-right:2em;

@media screen and (max-width:1200px){
margin-left:0px;
margin-right:0px;
}
`

const ArrowImg = styled.img`
width:24px;

transition:all ease 1s;


`

const ArrowDiv = styled.div`
width:50px !important;
display:flex;
justify-content:center;
align-items:center;
border-radius:160px;
transition: all .7s ease;
:hover{
background: rgba(68,68,68,.1);
}
  `

const StyledSlideDown = styled(SlideDown)`
//padding-top:50px;

@media screen and (max-width:1200px){
padding-left:0px  ;}
`

const StyledExtrasP = styled.p`
 font-family: "Theano Didot",Regular;
font-style: italic;
font-size: 160%;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
margin-bottom:0px;
margin-left:2%;
margin-top:5%;
text-align:left !important;
  @media screen and (max-width: 666px) {
   }
   
`

const StyledTextarea = styled(Textarea)`
border:1px solid lightgrey;
min-width:100%;
max-width:500px !important;
padding:15px;

:focus::placeholder{
       color:transparent;
  }
  
  :focus::-webkit-input-placeholder { color:transparent; }
:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */


`

const ToolBar = styled.div`
display:flex;
justify-content:space-between;
align-items:center; 
width:100%;
padding:7%; 
font-size:32px;
color: rgba(68,68,68,1);
    transition: all 0.7s ease;
`

const Icon = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items:center;
  justify-content:center;
height:51.7px !important;
width:51.7px !important;
 

  border-radius: 20rem;
  overflow: hidden;
  
  :hover{


  }
  
  ::before, ::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.25s ease;
  border-radius: 30px;
}
  
::before {
  transition-duration: 0.5s;
  box-shadow: inset 0 0 0 0px rgba(24, 140, 236,.2);
}

:hover::before {
  box-shadow: inset 0 0 0 60px  rgba(24, 140, 236,.2);
}


`
const PdfIcon = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items:center;
  justify-content:center;
height:51.7px !important;
width:51.7px !important;
   line-height:normal !important;


a{
width:32px;
height:32px;
line-height:auto !important;
}
  border-radius: 20rem;
  overflow: hidden;
  
  :hover{


  }
  
  ::before, ::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.25s ease;
  border-radius: 30px;
}
  
::before {
  transition-duration: 0.5s;
  box-shadow: inset 0 0 0 0px rgba(24, 140, 236,.2);
}

:hover::before {
  box-shadow: inset 0 0 0 60px  rgba(24, 140, 236,.2);
}


`

const DetailsDiv = styled.div`
background: white;

@media screen and (max-width:1200px){
padding-left:5%;
}
`


const ArrowIcon = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items:center;
  justify-content:center;
width:50px !important;
min-width:50px !important;
height:50px !important;
 

  border-radius: 20rem;
  overflow: hidden;
  
  :hover{
  color:white;
    transition: all 0.7s ease;

  }
  
  ::before, ::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.25s ease;
  border-radius: 30px;
}
  
::before {
  transition-duration: 0.5s;
  box-shadow: inset 0 0 0 0px rgba(24, 140, 236,.2);
}

:hover::before {
  box-shadow: inset 0 0 0 60px rgba(24, 140, 236,.2);
}


`

export class BookingTemplate extends React.Component {
  constructor(props) {
    super(props)
    const {  meridiem, focused, showTimezone, timezone } = props;
    let hour = '';
    let minute = '';
    const breakfastOptions = [
      {value: 1, label: '1 room'},
      {value: 2, label: '2 rooms'},
      {value: 3, label: '3 rooms'},
      {value: 4, label: '4 rooms'}
    ]

    let startDate = moment(props.reservation.reservationRooms[0].startDate.toString())
    let endDate = moment(props.reservation.reservationRooms[0].endDate.toString())

    this.state = {
      status:'pending',
      reservationID:props.reservation.customFields[0],
      stripeID:'',
      payment:'',
      rotation: 0,
      rotationSpecials: 0,
        startDate: startDate.format('DD.MM.YYYY'),
        endDate: endDate.format('DD.MM.YYYY'),
      rooms:props.reservation.reservationRooms,
      guests:0,
      totalGuests:0,
        studio:0,
        studioPlus:0,
        suite:0,
      formattedStudio: '',
      formattedStudioPlus: '',
      formattedSuite: '',
        stPrice:0,
        stpPrice:0,
        suPrice:0,
        totalPrice:props.reservation.balanceDetailed.grandTotal,
        deposit:props.reservation.balanceDetailed.paid,
        discount:0,
        loyaltyDiscount:0,
        breakfast:0,
        bikes:0,
        champagne:0,
        whiteWine:0,
        redWine:0,
        spa:0,
        laundry:0,
        formattedBreakfast:'',
        formattedBikes:'',
        formattedChampagne:'',
        formattedWhiteWine:'',
        formattedRedWine:'',
        formattedSpa:'',
        formattedLaundry:'',
      newBreakfast:0,
      newBikes:0,
      newChampagne:0,
      newWhiteWine:0,
      newRedWine:0,
      newSpa:0,
      newLaundry:0,
        allowCancel:false,
      hour,
      minute,
      meridiem,
      focused,
      timezone,
      showTimezone,
      bookingClosed:true,
      breakfastOptions:breakfastOptions,
      specialsClosed:true,

      selectedBreakfast:0,
      selectedBikes:0,
      selectedLaundry:0,
      selectedChampagne:0,
      selectedBreakfastGuests:specialsGuestOptions[0],
      selectedBreakfastDays:specialsDaysOptions[0],
      selectedBikeGuests:specialsGuestOptions[0],
      selectedBikeDays:specialsDaysOptions[0],
      selectedLaundryFillings:specialsDaysOptions[0], // max = nights
      selectedChampagneBottles:specialsDaysOptions[0], // max = nights
      specialsGuestOptions: specialsGuestOptions,
      specialsDaysOptions: specialsDaysOptions



    }

    this.rotate = this.rotate.bind(this);

  }

  componentDidMount(){

    for(let r=0;r<this.props.reservation.reservationRooms.length;r++ ){
        if(this.props.reservation.reservationRooms[r].roomTypeName==='STU'){
          this.setState({
            studio: this.state.studio++,
            totalGuests: this.state.totalGuests+this.props.reservation.reservationRooms[r].adults
          })
        }
        if(this.props.reservation.reservationRooms[r].roomTypeName==='STP'){
          this.setState({
            studioPlus: this.state.studioPlus++,
            totalGuests: this.state.totalGuests+this.props.reservation.reservationRooms[r].adults
          })
        }

        if(this.props.reservation.reservationRooms[r].roomTypeName==='SUI'){
          this.setState({
            suite: this.state.suite++,
            totalGuests: this.state.totalGuests+this.props.reservation.reservationRooms[r].adults
          })
        }
    }

    for(let r=0;r<this.props.reservation.reservationAdditionalProducts.length;r++ ){
if(this.props.reservation.reservationAdditionalProducts[r].itemCode==='BREAKFAST'){
  this.setState({
    breakfast: this.props.reservation.reservationAdditionalProducts[r].productQuantity,
    formattedBreakfast: this.props.reservation.reservationAdditionalProducts[r].productQuantity + 'x Breakfast'
  })
}

if(this.props.reservation.reservationAdditionalProducts[r].itemCode==='LAUNDRY'){
  this.setState({
    laundry: this.props.reservation.reservationAdditionalProducts[r].productQuantity,
    formattedLaundry: this.props.reservation.reservationAdditionalProducts[r].productQuantity + 'x Laundry'
  })
}

if(this.props.reservation.reservationAdditionalProducts[r].itemCode==='SPA'){
  this.setState({
    spa: this.props.reservation.reservationAdditionalProducts[r].productQuantity,
    formattedSpa: this.props.reservation.reservationAdditionalProducts[r].productQuantity + 'x Spa'
  })
}

if(this.props.reservation.reservationAdditionalProducts[r].itemCode==='BIKE'){
  this.setState({
    bikes: this.props.reservation.reservationAdditionalProducts[r].productQuantity,
    formattedBikes: this.props.reservation.reservationAdditionalProducts[r].productQuantity + 'x Bikes'
  })
}

if(this.props.reservation.reservationAdditionalProducts[r].itemCode==='WW'){
  this.setState({
    whiteWine: this.props.reservation.reservationAdditionalProducts[r].productQuantity,
    formattedWhiteWine: this.props.reservation.reservationAdditionalProducts[r].productQuantity + 'x White Wine'
  })
}

if(this.props.reservation.reservationAdditionalProducts[r].itemCode==='RW'){
  this.setState({
    redWine: this.props.reservation.reservationAdditionalProducts[r].productQuantity,
    formattedRedWine: this.props.reservation.reservationAdditionalProducts[r].productQuantity + 'x Red Wine'
  })
}

if(this.props.reservation.reservationAdditionalProducts[r].itemCode==='CHAMPUS'){
  this.setState({
    redWine: this.props.reservation.reservationAdditionalProducts[r].productQuantity,
    formattedChampagne: this.props.reservation.reservationAdditionalProducts[r].productQuantity + 'x Champagne'
  })
}



    }


      let formattedStudio='';
    let formattedStudioPlus='';
    let formattedSuite='';
    if(this.state.suite!=0){
      formattedSuite = this.state.suite+'x '+ 'Suite'
    }

    if(this.state.studio!=0){
      formattedStudio = this.state.studio+'x '+ 'Studio'
    }

    if(this.state.studioPlus!=0){
      formattedStudioPlus = this.state.studioPlus+'x '+ 'Studio Plus'
    }




    this.setState({
      formattedStudio: formattedStudio,
      formattedStudioPlus: formattedStudioPlus,
      formattedSuite: formattedSuite,
    })
  }

  rotate(){
    let newRotation = this.state.rotation;
    if(newRotation === -180){
      newRotation += 180;
    }
    else
    if(newRotation === 0){
      newRotation -= 180;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  rotateSpecials(){
    let newRotation = this.state.rotationSpecials;
    if(newRotation === -180){
      newRotation += 180;
    }
    else
    if(newRotation === 0){
      newRotation -= 180;
    }
    this.setState({
      rotationSpecials: newRotation,
    })
  }

  toggleBooking () {
    this.setState({bookingClosed: !this.state.bookingClosed});
    this.rotate();
  }

  toggleSpecials () {
    this.setState({specialsClosed: !this.state.specialsClosed});
    this.rotateSpecials();
  }

  onTimeChange(options) {
    // do something
  }

  onFocusChange(focusStatue) {
    // do something
  }

  setSelectedBreakfastGuests  = (selectedGuests) => {

    this.setState({
      selectedBreakfastGuests: selectedGuests

    });
    this.handleBreakfastSelection(this.state.selectedBreakfastDays.value, selectedGuests.value)
  }

  setSelectedBreakfastDays = (selectedDays) => {

    this.setState({
      selectedBreakfastDays: selectedDays

    });
    this.handleBreakfastSelection(selectedDays.value,this.state.selectedBreakfastGuests.value)

  }


  setSelectedBikeGuests  = (selectedGuests) => {

    this.setState({
      selectedBikeGuests: selectedGuests

    });
    this.handleBikeSelection(this.state.selectedBikeDays.value, selectedGuests.value)
  }

  setSelectedBikeDays = (selectedDays) => {

    this.setState({
      selectedBikeDays: selectedDays

    });
    this.handleBikeSelection(selectedDays.value,this.state.selectedBikeGuests.value)

  }

  setSelectedLaundryFillings = (selectedFillings) => {

    this.setState({
      selectedLaundryFillings: selectedFillings

    });
    this.handleLaundrySelection(selectedFillings.value)

  }


  setSelectedChampagneBottles = (selectedBottles) => {

    this.setState({
      selectedChampagneBottles: selectedBottles

    });
    this.handleChampagneSelection(selectedBottles.value)

  }



  //todo: cancel reservation in stripe & cloudbeds
  cancelBooking(){
if(this.state.payment==='ch'){
  let refund = refundCharge(this.state.stripeID)
  refund.then(function(res) {
if(res.status==='succeeded'){
  this.setState({
    status: 'cancelled'
  })
}
else{
  //todo: show refund reservation failed + send email: failed refund of charge
}

  })

  }
if(this.state.payment==='pi'){
  let refund = refundPaymentIntent(this.state.stripeID)
  refund.then(function(res) {
    if(res.status==='succeeded'){
      this.setState({
        status: 'cancelled'
      })
    }

  })
}
else{
  //todo: show refund reservation failed + send email: failed refund of paymentIntent
}

// cancel reservation in cloudbeds

   let cloudbedsCancel= cancelReservation(this.state.reservationID)
    cloudbedsCancel.then(function(res) {
      if(res.success===true){
        // todo: check if mail will be send via cloudbeds pms otherwise send cancellation confirmation for both user and devops
      }
      else{
        //todo: send mail cloudbeds cancellation failed to devops

      }
    });
  }


  //todo: add reservation items to cloudsbeds reservation and update corresponding pament_intent/charge in stripe
  addReservationItems(){
    let items=[];
    if(this.state.newBreakfast!=0){
      let br = {itemID:'id', itemQuantity:this.state.newBreakfast, itemPrice:(this.state.newBreakfast*15)}
      items.push(br)
    }
    if(this.state.newBikes!=0){
      let bi = {itemID:'id', itemQuantity:this.state.newBikes, itemPrice:(this.state.bikeRate*12)}
      items.push(bi)

    }
    if(this.state.newLaundry!=0){
      let la = {itemID:'id', itemQuantity:this.state.newLaundry, itemPrice:(this.state.newLaundry*15)}
      items.push(la)

    }
    if(this.state.newSpa!=0){
      let sp = {itemID:'id', itemQuantity:this.state.newSpa, itemPrice:(this.state.newSpa*25)}
      items.push(sp)

    }
    if(this.state.selectedChampagne!=0){
      let ch = {itemID:'id', itemQuantity:this.state.selectedChampagne, itemPrice:(this.state.newChampagne*70)}
      items.push(ch)

    }
    if(this.state.selectedWhiteWine !=0){
      let wh = {itemID:'id', itemQuantity:this.state.selectedWhiteWine, itemPrice:(this.state.newWhiteWine*70)}
      items.push(wh)

    }

    if(this.state.selectedRedWine !=0){
      let re = {itemID:'id', itemQuantity:this.state.selectedRedWine, itemPrice:(this.state.newRedWine*70)}
      items.push(re)

    }
let success = false;
    for(let i=0;items.length<i;i++) {

     let item=  postReservationItem(items[i], this.state.reservationID)
      item.then(function(res) {
        if(res.success===true){
          //todo: show success for current item (notification)
          success = true;
        }
        else{
          //todo send mail to devops failed to add current item to reservation
        }
      })

      }

  }


  //todo:  update corresponding pament_intent/charge metadata in stripe)
  addReservationNotes(newNotes, oldNotes){
    let now = moment().format('MMMM Do YYYY, h:mm:ss a');
    let finalNotes = "updated on " + now + ":" + newNotes + " ," + oldNotes
    let postNotes = postReservationNotes(finalNotes, this.state.reservationID)
    postNotes.then(function(res) {
      if(res.success===true){
        //todo: show notes updated successful notification

      }
      else{
        //todo show failed to update notes notification

      }
    })


  }

  //todo: post arrival time to cloudbeds reservation (and update corresponding stripe payment_intent/charge metadata)
  postArrivalTime(arrival_time){
let postArrival = postArrivalTime(arrival_time, this.state.reservatonID)
    postArrival.then(function(res) {
      if(res.success===true){
        //todo: show arrival time updated successful notification
      }
      else{
        //todo show failed to update arrival time notification
      }
    })

    }





  render() {
    return (
      <Div >

        <FlexDivHeader>
          <ArrowIcon onClick={() => this.toggleBooking()}> <ArrowImg style={{transform: `rotate(${this.state.rotation}deg)`}} src={arrowDown}/>
          </ArrowIcon>
          <DivIDOne>
            <Span>Booking {this.state.reservationID}</Span>
            <DetailsA>{this.state.startDate} - {this.state.endDate}, {this.state.totalGuests} guest/s in {this.state.rooms.length} room/s</DetailsA>
          </DivIDOne>

        </FlexDivHeader>
        <FlexBox>

        </FlexBox>
        <StyledSlideDown closed={this.state.bookingClosed} className={'css-2289437'}>
<br/>
          <DetailsDiv>
          <FlexDivPadding>
            <CheckinDiv>
              <Span>Check-In

              </Span>
              <DateDiv> {this.state.startDate}
                <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false"
                     viewBox="0 0 1000 1000">
                  <path
                    d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z"></path>
                </svg>

              </DateDiv>



            </CheckinDiv>
            <CheckinDiv>
              <Span>Check-Out</Span>
              <DateDiv>{this.state.endDate}</DateDiv>
            </CheckinDiv>
            <CheckinDiv>
        <Span>Guests</Span>
        <DateDiv>{this.state.totalGuests}</DateDiv>
            </CheckinDiv>


          </FlexDivPadding>
          <br/>
          <FlexDiv>

              <CheckinDiv>
              <Span>Rooms</Span>
              <DateDiv>{this.state.formattedStudio}<br style={{display:(this.state.studio!=0) ? 'block':'none'}} />{this.state.formattedStudioPlus} <br style={{display:(this.state.studioPlus!=0) ? 'block':'none'}}/> {this.state.formattedSuite}</DateDiv>
            </CheckinDiv>
<br/>
            <SpecialsDiv>
              <Span>Specials</Span>
              <DivSpecial><DivBreakfast style={{display:(this.state.breakfast!=0) ? 'block':'none'}}><div>{this.state.formattedBreakfast}</div>  </DivBreakfast> <DivBreakfast style={{display:(this.state.bikes!=0) ? 'block':'none'}}><div>{this.state.formattedBreakfast}</div></DivBreakfast>
                <DivBreakfast style={{display:(this.state.laundry!=0) ? 'block':'none'}}><div>{this.state.formattedLaundry}</div></DivBreakfast>
                <DivBreakfast style={{display:(this.state.champagne!=0) ? 'block':'none'}}><div>{this.state.formattedChampagne}</div></DivBreakfast>
                <DivBreakfast style={{display:(this.state.redWine!=0) ? 'block':'none'}}><div>{this.state.formattedRedWine}</div></DivBreakfast>
                <DivBreakfast style={{display:(this.state.whiteWine!=0) ? 'block':'none'}}><div>{this.state.formattedWhiteWine}</div></DivBreakfast>
                <DivBreakfast style={{display:(this.state.spa!=0) ? 'block':'none'}}><div>{this.state.formattedSpa}</div></DivBreakfast>

              </DivSpecial>
            </SpecialsDiv>


          </FlexDiv>
          <br/>
          <FlexDiv>
            <CheckinDiv/>
            <CheckinDiv>
              <Span>Prepaid</Span>
              <DateDiv> {this.state.deposit} EUR</DateDiv>
            </CheckinDiv>
            <CheckinDiv>
              <Span>Total</Span>
              <DateDiv> {this.state.totalPrice} EUR</DateDiv>
            </CheckinDiv>
          </FlexDiv>
          <br/>
          </DetailsDiv>

          <DivArrival>
            <ToolBar>
              <Icon>  <AiOutlineShareAlt style={{zIndex: 1}}/> </Icon>

              <Icon> <AiOutlineMail style={{zIndex: 1}}/> </Icon>
              <Icon>  <AiOutlinePhone style={{zIndex: 1}}/> </Icon>

            </ToolBar>
        <SpanCentered>Estimated time of arrival</SpanCentered>
  <TimePickerWrapper
            showTimezone // show the timezone, default false
            focused // whether to show timepicker modal after rendered. default false
            time="13:05" // initial time, default current time
            // or
            theme="material"
               timeMode="24" // use 24 or 12 hours mode, default 24
          />



          </DivArrival>

        </StyledSlideDown>




      </Div>
    )
  }
}
