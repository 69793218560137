
import React from 'react';
import TimePicker from 'react-times';

import timeHelper from './timezone';
import ICONS from './icons';
import styled from "@emotion/styled";


const Div = styled.div`
.picker_container{
margin-left:auto;
margin-right:auto;
margin-bottom:20px;
}

.time_picker_container{
width:50%;
margin-left:auto;
margin-right:auto;
@media screen and (max-width: 666px) {
width:80%;

}

.react_times_button{
cursor:pointer;
height:50px;

  border-radius: 10rem;
  //color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  //font-weight:bold;
  font-size:19px;
width:100%;
margin-left:auto;
margin-right:auto;

  
:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(68,68,68,.1);
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
}
 
:hover:before {
  width: 100%;
}

  
}


   }


`
class TimePickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { defaultTime, meridiem, focused, showTimezone, timezone } = props;
    let hour = '';
    let minute = '';
    if (!defaultTime) {
      [hour, minute] = timeHelper.current().split(/:/);
    } else {
      [hour, minute] = defaultTime.split(/:/);
    }

    this.state = {
      hour,
      minute,
      meridiem,
      focused,
      timezone,
      showTimezone,
    };

    this.onFocusChange = this.onFocusChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.handleFocusedChange = this.handleFocusedChange.bind(this);
  }

  onTimeChange(options) {
    const {
      hour,
      minute,
      meridiem
    } = options;

    this.setState({ hour, minute, meridiem });
  }

  onFocusChange(focused) {
    console.log(`onFocusChange: ${focused}`);
    this.setState({ focused });
  }

  handleFocusedChange() {
    const { focused } = this.state;
    this.setState({ focused: !focused });
  }

  get basicTrigger() {
    const { hour, minute } = this.state;
    return (
      <div
        onClick={this.handleFocusedChange}
    className="time_picker_trigger"
      >
      <div>
        Click to open panel<br />
    {hour}:{minute}
    </div>
    </div>
  );
  }

  get customTrigger() {
    return (
      <div
        onClick={this.handleFocusedChange}
    className="time_picker_trigger"
      >
      {ICONS.time}
    </div>
  );
  }

  get trigger() {
    const { customTriggerId } = this.props;
    const triggers = {
      0: (<div />),
      1: this.basicTrigger,
      2: this.customTrigger
    };
    return triggers[customTriggerId] || null;
  }

  render() {
    const {
      hour,
      minute,
      focused,
      meridiem,
      timezone,
      showTimezone,
    } = this.state;

    return (
      <Div className="time_picker_wrapper">
      <TimePicker
        trigger={this.trigger}
    {...this.props}
    focused={focused}
    meridiem={meridiem}
    timezone={timezone}
    onFocusChange={this.onFocusChange}
    onTimeChange={this.onTimeChange}
    showTimezone={showTimezone}
    time={hour && minute ? `${hour}:${minute}` : null}
    />
    </Div>
  );
  }
}

TimePickerWrapper.defaultProps = {
  customTriggerId: null,
  defaultTime: null,
  focused: false,
  meridiem: null,
  showTimezone: false
};

export default TimePickerWrapper;
